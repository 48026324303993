import axios from 'axios'

const axiosConfig = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL || '',
  headers: {
    accept: 'application/json'
  }
})

axiosConfig.interceptors.request.use(config => {
  return config
})

axiosConfig.interceptors.response.use(
  response => {
    return response
  },
  err => {
    if (err.response.status === 401) {
      localStorage.removeItem('humetrics_user_token')
      location.reload()
    }
    // if (err.response.status === 429) {
    //   return false
    // }
    return Promise.resolve(err.response)
  }
)

export { axiosConfig }
