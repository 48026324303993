<template>
  <transition name="fade-up">
    <div
      v-show="show"
      class="position-fixed bottom-0 end-0 m-2"
      style="z-index: 1080"
    >
      <div :class="`alert-${state}`" class="alert" role="alert">
        <div class="d-flex align-items-center">
          <h4 class="alert-heading">
            <i class="bi pe-1" :class="`bi-${icon || default_icon}`"></i>
            {{ title }}
          </h4>
        </div>
        <p class="mb-0">{{ info }}</p>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'Alerts',
  props: ['show'],
  data () {
    return {
      default_icon: null
    }
  },
  computed: {
    state () {
      const vm = this
      const state = vm.$store.state.alerts.state
      switch (state) {
        case 'success':
          vm.default_icon = 'check-circle-fill'
          break
        case 'error':
          vm.default_icon = 'exclamation-triangle-fill'
          break
        case 'warning':
          vm.default_icon = 'exclamation-triangle-fill'
          break
      }
      return state
    },
    icon () {
      return this.$store.state.alerts.icon
    },
    title () {
      return this.$store.state.alerts.title
    },
    info () {
      return this.$store.state.alerts.info
    }
  },
  watch: {
    show (data) {
      if (data) {
        setTimeout(() => {
          this.$store.commit('Alerted')
        }, 2000)
      }
    }
  },
  created () {}
}
</script>
