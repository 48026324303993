import 'bootstrap-vue/dist/bootstrap-vue.css'
import '@/assets/scss/custom.scss'
import '@/assets/scss/animate.scss'
import '@/assets/scss/print.scss'
import '@/assets/scss/breakpointsForJS.scss'
import 'bootstrap-icons/font/bootstrap-icons.css'
import 'hacktimer'
import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import i18n from '@/lang/lang.js'
import '@/dateFormat.js'
import { TablePlugin, PaginationPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import VueMoment from 'vue-moment'
import moment from 'moment-timezone'
import 'viewerjs/dist/viewer.css'
import VueViewer from 'v-viewer'

require('moment/locale/zh-tw')

Vue.use(VueMoment, {
  moment
})

Vue.use(TablePlugin)
Vue.use(PaginationPlugin)
Vue.use(VueCompositionAPI)
Vue.use(VueViewer)

Vue.config.productionTip = false

Vue.prototype.$getUTCseconds = function (date) {
  return Math.round(new Date(date).getTime() / 1000)
}

Vue.prototype.$getUTCmilliseconds = function (date) {
  let UTCmilliseconds
  if (isNaN(date)) {
    UTCmilliseconds = new Date(date).getTime()
  } else {
    UTCmilliseconds = new Date(date * 1000)
  }
  return UTCmilliseconds
}

Vue.prototype.$getTimeZoneDate = function (date, timeZone, format) {
  let timeZoneDate
  if (isNaN(date) || !isNaN(Date.parse(date))) {
    timeZoneDate = moment(new Date(date).getTime())
      .tz(timeZone)
      .format(format)
  } else {
    timeZoneDate = moment(new Date(date * 1000))
      .tz(timeZone)
      .format(format)
  }
  return timeZoneDate
}

if (process.env.NODE_ENV === 'development') {
  // makeServer()
}

// check cookie before create vue, avoid language switch causing dashboard display errors
var token = localStorage.getItem('humetrics_user_token')
if (token) {
  var userList = JSON.parse(localStorage.getItem('user_list') || '[]')
  for (const key in userList) {
    if (userList[key].token === token) {
      switch (userList[key].agency.locale) {
        case 'zh_TW':
          i18n.locale = 'zh'
          break
        case 'ja':
          i18n.locale = 'ja'
          break
        default:
          i18n.locale = 'en'
          break
      }
      break
    }
  }
}

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
