import { auth } from '@/http/api/auth.js'
import { dashboard } from '@/http/api/dashboard.js'
import { report } from '@/http/api/report.js'
import i18n from '@/lang/lang.js'
import moment from 'moment-timezone'
import Echo from 'laravel-echo'
import io from 'socket.io-client'
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isLoading: false,
    loadingText: null,
    isAlerting: false,
    hasStatistics: false,
    viewport: null,
    windowWidth: 0,
    breakpoints: {
      xs: 0,
      sm: 576,
      md: 768,
      lg: 992,
      xl: 1200,
      xxl: 1400
    },
    alerts: {
      state: null,
      icon: null,
      title: null,
      info: null
    },
    token: {
      headers: {
        Authorization: null
      }
    },
    userInfo: null,
    notifyList: null,
    updateCycle: process.env.VUE_APP_API_UPDATE_CYCLE || 1000,
    timezone: 'UTC',
    toDayUTCseconds: Math.round(new Date().getTime() / 1000),
    toDayUTCmilliseconds: new Date().getTime(),
    echoClient: null,
    showAnnouncementModal: false,
    announcement: {
      title: '',
      content: ''
    },
    isWelcomeVisible: true
  },
  getters: {
    sortNotify: state => {
      var copyNotifications = []
      if (!state.notifyList || !state.notifyList.length) return ''
      // 匯集所有裝置的通知
      state.notifyList.forEach(notify => {
        // 合併陣列
        const array = JSON.parse(JSON.stringify(notify.notifies))
        copyNotifications = copyNotifications.concat(array)
      })
      // 將時間順序排好
      return copyNotifications.sort((a, b) => {
        return (
          Date.parse(b.created_at).valueOf() -
          Date.parse(a.created_at).valueOf()
        )
      })
    },
    timeZoneOffset: state => {
      return moment.tz(state.timezone).format('Z')
    },
    notify_type_name: state => {
      if (state.userInfo) {
        return {
          leaveBed: i18n.t('__notifyTypeLeaveBed'), // 離床通知
          leaveTimeout: i18n.t('__notifyTypeLeaveBed'), // 離床通知
          siteTimeout: i18n.t('__notifyTypeLeaveBed'), // 離床通知
          lyingTimeout: i18n.t('__notifyTypeLying'), // 臥床通知
          changePosture: i18n.t('__notifyTypeChangePosture'), // 翻身通知
          respirationRate: i18n.t('__notifyTypeRespiration'), // 呼吸通知
          respirationRateGreater: i18n.t('__notifyTypeRespiration'), // 呼吸通知
          respirationRateLess: i18n.t('__notifyTypeRespiration'), // 呼吸通知
          rrAbnormal: i18n.t('__notifyTypeRespiration'), // 呼吸通知
          restlessRateGreater: i18n.t('__notifyTypeRestless'), // 體動通知
          mqttStatus: i18n.t('__notifyTypeNetwork'), // 網路通知
          heartRate: i18n.t('__heartRateNotify'),
          heartRateGreater: i18n.t('__heartRateNotify'),
          heartRateLess: i18n.t('__heartRateNotify'),
          spo2: i18n.t('__spo2Notify'),
          spo2Less: i18n.t('__spo2Notify'),
          temp: i18n.t('__tempNotify'),
          tempGreater: i18n.t('__tempNotify'),
          tempLess: i18n.t('__tempNotify'),
          deviceDisconnected: i18n.t('__deviceDisconnected')
        }
      }
    },
    notify_speaker_type_name: state => {
      if (state.userInfo) {
        return {
          leaveBed: i18n.t('__notifyTypeSpeakerLeaveBed'), // 離床通知
          leaveTimeout: i18n.t('__notifyTypeSpeakerLeaveBedTimeout'), // 離床通知
          lyingTimeout: i18n.t('__notifyTypeSpeakerLying'), // 臥床通知
          respirationRateGreater: i18n.t('__notifyTypeSpeakerRespirationGreater'), // 呼吸通知
          respirationRateLess: i18n.t('__notifyTypeSpeakerRespirationLess'), // 呼吸通知
          rrAbnormal: i18n.t('__notifyTypeSpeakerRespirationAbnormal'), // 呼吸通知
          restlessRateGreater: i18n.t('__notifyTypeSpeakerRestlessGreater'), // 體動通知
          heartRateGreater: i18n.t('__notifyTypeSpeakerHeartRateGreater'),
          heartRateLess: i18n.t('__notifyTypeSpeakerHeartRateLess'),
          spo2Less: i18n.t('__notifyTypeSpeakerSpo2Less'),
          tempGreater: i18n.t('__notifyTypeSpeakerTempGreater'),
          tempLess: i18n.t('__notifyTypeSpeakerTempLess'),
          deviceDisconnected: i18n.t('__deviceDisconnected')
        }
      }
    },
    notify_condition_name: state => {
      if (state.userInfo) {
        return {
          leaveBed: i18n.t('__notifyConditionLeaveBed'), // 離床
          leaveTimeout: i18n.t('__notifyConditionLeaveTimeout'), // 離床時間大於
          lyingTimeout: i18n.t('__notifyConditionLyingTimeout'), // 臥床時間大於
          respirationRateGreater: i18n.t(
            '__notifyConditionRespirationRateGreater'
          ), // 呼吸率大於
          respirationRateLess: i18n.t('__notifyConditionRespirationRateLess'), // 呼吸率小於
          restlessRateGreater: i18n.t('__notifyConditionRestlessRateGreater'), // 體動值大於
          rrAbnormal: i18n.t('__notifyConditionRespirationRateAbnormal'), // 呼吸偵測異常
          siteTimeout: i18n.t('__notifyConditionSiteTimeout'), // 坐床時間持續
          disconnect: i18n.t('__notifyConditionOffline'), // 離線
          connect: i18n.t('__notifyConditionOnline'), // 連線
          heartRateGreater: i18n.t('__notifyConditionHeartRateGreater'), // 心率大於
          heartRateLess: i18n.t('__notifyConditionHeartRateLess'), // 心率小於
          spo2Less: i18n.t('__notifyConditionSpo2Less'), // 血氧小於
          tempGreater: i18n.t('__notifyConditionTempGreater'), // 溫度大於
          tempLess: i18n.t('__notifyConditionTempLess'), // 溫度小於
          deviceDisconnected: i18n.t('__deviceDisconnected')
        }
      }
    },
    notify_unit: state => {
      if (state.userInfo) {
        return {
          leaveBed: '',
          leaveTimeout: i18n.t('__minute'),
          siteTimeout: i18n.t('__second'),
          LyingTimeout: i18n.t('__minute'),
          respirationRateGreater: i18n.t('__times_min'),
          respirationRateLess: i18n.t('__times_min'),
          restlessRateGreater: '',
          rrAbnormal: '',
          heartRateGreater: i18n.t('__times_min'),
          heartRateLess: i18n.t('__times_min'),
          spo2Less: i18n.t('__percent_min'),
          tempGreater: i18n.t('__degree_min'),
          tempLess: i18n.t('__degree_min'),
          deviceDisconnected: i18n.t('__deviceDisconnected')
        }
      }
    }
  },
  actions: {
    refreshNotifyList ({ commit }) {
      var copyNotifyList = [...this.state.notifyList]
      copyNotifyList.forEach(item => {
        item.notifies = item.notifies.filter(function (notify) { // 只保留1小時的通知
          return (new Date() - new Date(notify.created_at)) < 60 * 60 * 1000
        })
      })
      commit('setNotify_list', copyNotifyList)
      copyNotifyList = null
    },
    getNotification ({ commit }) {
      dashboard
        .getDeviceNotify(this.state.token)
        .then(res => {
          if (res.status <= 201) {
            commit('setNotify_list', res.data.data)
          }
        })
        .catch(error => {
          commit('AlertsInfo', {
            state: 'error',
            title: i18n.t('__error'),
            info: error
          })
          commit('Alerted')
        })
    },
    getUserInfo ({ commit }) {
      setTimeout(() => {
        commit('getToken')
        auth.getUser(this.state.token).then(res => {
          if (res.status <= 201) {
            // 初始化laravel-echo連線
            var EchoClient = new Echo({
              broadcaster: 'socket.io',
              host: 'https://' + window.location.hostname,
              client: io,
              options: {
                reconnection: true,
                reconnectionDelay: 1000,
                reconnectionDelayMax: 5000,
                timeout: 30000,
                transports: ['websocket', 'polling']
              }
            })
            // let reconnectInterval = null
            EchoClient.connector.socket.on('disconnect', () => {
              console.log('socket disconnect')
              // 當斷線時，開始重新連線
              // 設定重新連線的時間間隔為 5 秒
              // reconnectInterval = setInterval(() => {
              //   EchoClient.connector.socket.connect()
              // }, 5000)
            })
            EchoClient.connector.socket.on('connect', () => {
              console.log('socket connect')
              // 當重新連線成功時，清除重新連線的 interval
              // clearInterval(reconnectInterval)
              // reconnectInterval = null
            })
            commit('setEchoClient', EchoClient.channel('broadcast_database_dashboard.' + res.data.data.agency_id).subscribed((channel) => {
              // console.log('Subscribed to channel: ' + channel.name)
            }))

            if (res.data.data.aggregateChannelName) {
              commit('setEchoClient', EchoClient.channel('broadcast_database_' + res.data.data.aggregateChannelName).subscribed((channel) => {
                // console.log('Subscribed to channel: ' + channel.name)
              }))
            }

            EchoClient.channel('broadcast_database_AnnouncementEvent.' + res.data.data.agency_id)
              .listen('AnnouncementEvent', (event) => {
                commit('showAnnouncementModal', event.announcement)
              })

            EchoClient.channel('broadcast_database_ForaEvent.' + res.data.data.agency_id)
              .listen('ForaEvent', (event) => {
              })

            // 更新cookie，比對如果沒有該token則新增一筆
            var token = localStorage.getItem('humetrics_user_token')
            var userList = JSON.parse(localStorage.getItem('user_list') || '[]')
            var isContainUser = false
            var updateKey = null
            for (const key in userList) { // 確認是否有使用者已經在cookie內
              if (userList[key].token === token) {
                updateKey = key
                isContainUser = true
                break
              }
            }
            if (!isContainUser) {
              const cookieData = {}
              cookieData.agency = {}
              cookieData.agency.locale = res.data.data.agency.locale
              cookieData.agency.timezone = res.data.data.agency.timezone
              cookieData.agency.name = res.data.data.agency.name
              cookieData.username = res.data.data.username
              cookieData.token = token
              cookieData.expire = Date.now() + 86400000 * 30 // 三十天後過期
              userList.push(cookieData)
            } else {
              // 更新資料
              userList[updateKey].token = token
              userList[updateKey].expire = Date.now() + 86400000 * 30
            }
            userList = userList.filter(function (user) { // 踢掉過期的使用者資料
              return Date.now() < user.expire
            })
            localStorage.setItem('user_list', JSON.stringify(userList))
            commit('Timezone', res.data.data.agency.timezone)
            commit('acceptUserInfo', res.data.data)
            i18n.locale =
              res.data.data.agency.locale === 'zh_TW'
                ? 'zh'
                : res.data.data.agency.locale
          }
        })
      }, 0)
    },
    getStatistics ({ commit }) {
      return new Promise((resolve, reject) => {
        var config = Object.assign({}, this.state.token)
        config.params = {}
        config.params.ended_at = new Date().format('yyyy-MM-dd')
        report.getStatistics(config).then(res => {
          if (res.status <= 201 && res.data.status === 'success') {
            commit('Statistics', !Array.isArray(res.data.data))
            resolve()
          }
        })
      })
    },
    updateUserToken ({ commit }) {
      commit('getToken')
    }
  },
  mutations: {
    Timezone (state, data) {
      state.timezone = data
    },
    Loading (state) {
      state.isLoading = true
    },
    LoadingText (state, data) {
      state.loadingText = data
    },
    Loaded (state) {
      state.isLoading = false
      state.loadingText = null
    },
    Alerted (state) {
      state.isAlerting = !state.isAlerting
    },
    AlertsInfo (state, data) {
      state.alerts.state = data.state
      state.alerts.icon = data.icon
      state.alerts.title = data.title
      state.alerts.info = data.info
    },
    Statistics (state, data) {
      state.hasStatistics = data
    },
    getViewport (state) {
      const pseudoElementStyleContent = getComputedStyle(
        document.querySelector('body'),
        ':after'
      ).content
      // eslint-disable-next-line no-useless-escape
      state.viewport = pseudoElementStyleContent.replace(/\"/g, '')
    },
    setWindowWidth (state, value) {
      state.windowWidth = value
    },
    setNotify_list (state, data) {
      state.notifyList = data
    },
    acceptUserInfo (state, data) {
      state.userInfo = data
    },
    getToken (state) {
      state.token.headers.Authorization =
        'Bearer ' + localStorage.getItem('humetrics_user_token')
    },
    setEchoClient (state, EchoClient) {
      state.echoClient = EchoClient
    },
    pushToNotifyList (state, notify) {
      var targetItem = state.notifyList.find(function (item) { // 找到目標id的物件
        return item.resident_id === notify.resident_id
      })
      if (targetItem) { // 加入新通知
        targetItem.notifies.unshift(notify)
        targetItem.notifies = targetItem.notifies.filter(function (notify) { // 保留兩小時內的通知
          return (new Date() - new Date(notify.created_at)) < 60 * 60 * 2 * 1000
        })
      }
    },
    showAnnouncementModal (state, announcement) {
      state.showAnnouncementModal = true
      state.announcement = announcement
    },
    closeAnnouncementModal (state) {
      state.showAnnouncementModal = false
      state.announcement = { title: '', content: '' }
    },
    setWelcomeVisibility (state, isVisible) {
      state.isWelcomeVisible = isVisible
    }
  },
  modules: {}
})
