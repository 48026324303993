import { axiosConfig } from './axios-config.js'

export const dashboard = {
  // 取得夜間睡眠時間
  getSleepingTime: token => axiosConfig.get('/api/dashboard/sleep', token),

  // 更新夜間睡眠時間
  updateSleepingTime: (data, token) => axiosConfig.post('/api/dashboard/sleep', data, token),

  // 取得裝置狀態
  /*
  00: Sleep (SLP),睡眠
  02: Sitting on the bed,床上坐姿 (SIT)
  07: Lying on the bed (LYB)臥床
  08: Leave the bed, (LVB)離床
  09: Chang posture, (CP)翻身動作
  27: Abnormal vital sign(AVS)異常衰弱
  */
  getDeviceState: token => axiosConfig.get('/api/dashboard/resident', token),

  // 取得裝置通知
  /* Notify Type
    leaveBed
    lyingTimeout
    respirationRateGreater
    respirationRateLess
    restlessRateGreater
  */
  getDeviceNotify: token => axiosConfig.get('/api/dashboard/notify', token)
}
